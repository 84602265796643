import React, { createContext, useMemo } from "react";
import { Menu, useMenuGetAll } from "hooks/useMenu";
import { useUser } from "hooks/useUser";

export interface IMenusContext {
  data: Menu[] | null | undefined;
  isLoading: boolean;
  isFetched: boolean;
  error: string | null;
}

const MenusContext = createContext({} as IMenusContext);

const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn } = useUser();
  const { data, isLoading, isFetched, error, refetch } = useMenuGetAll({
    enabled: isSignedIn,
  });

  const contextValue = useMemo(
    () => ({
      data,
      isLoading,
      isFetched,
      error,
      refetch,
    }),
    [data, isLoading, isFetched, error, refetch]
  );

  return (
    <MenusContext.Provider value={contextValue}>
      {children}
    </MenusContext.Provider>
  );
};

export { MenuProvider as default, MenusContext };
