import { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { UserContext } from "context/UserProvider";
import { clearBrowserCache, deleteCookie } from "helpers/storageUtilities";
import { authConfig, protectedResources } from "authConfig";
import { z } from "zod";
import { toast } from "react-toastify";
import {
  onErrorRequest,
  onErrorUpdateMutation,
  onSuccessfulMutation,
  onSuccessfulRequest,
} from "types";
import { useMsal } from "@azure/msal-react";
import { isNullEmptyOrWhitespace } from "helpers/common";

export const userSchema = z.object({
  id: z.string(),
  username: z.string(),
  displayName: z.string(),
  email: z.string().email(),
  permissionGroupId: z.number().optional(),
  permissionLevelId: z.number().optional(),
  roleId: z.string().optional(),
  assignedFarms: z.array(z.string()).optional(),
});

export type UserSchema = z.infer<typeof userSchema>;

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
};

type useUserGetMeProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
export const useUserGetMe = ({
  enabled = true,
  onSuccess,
  onError,
}: useUserGetMeProps = {}) => {
  const { isLoading, isFetched, execute, error } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onSuccess: (response, responseBody, variables) => {
      return onSuccess?.(variables);
    },
    onError,
  });

  const [data, setData] = useState<UserSchema | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data, error } = await execute("GET", "/api/userme-get");
    if (authConfig.mode === "b2c" && error === "request not ready") {
      // this is expected behavior for B2C
      setData(undefined);
      return;
    }

    const user = data?.d;

    const result = userSchema.safeParse(user);
    if (!result.success) {
      console.error(result);
      onError?.("PC1015: Invalid user data");
      setData(undefined);

      return;
    }

    const newData = result.data;

    setData(newData);

    return newData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, data, error, fetchData };
};

export const userSyncSchema = z.object({
  email: z.string().email(),
  displayName: z.string().optional(),
});
export type UserSyncSchema = z.infer<typeof userSyncSchema>;
type useUserSyncProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSync = ({ onSuccess, onError }: useUserSyncProps = {}) => {
  // This is only for MSAL
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: UserSyncSchema) => {
      const { data } = await execute(
        "PUT",
        "/api/usersync-externalprovider-put",
        user
      );

      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

export const userSignupSchema = z.object({
  email: z.string().email(),
  displayName: z.string(),
});
export type UserSignupSchema = z.infer<typeof userSignupSchema>;
type useUserSignupProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useUserSignup = ({
  onSuccess,
  onError,
}: useUserSignupProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: UserSignupSchema, variables?: any) => {
      const { data } = await execute(
        "POST",
        "/api/usersignup-post",
        user,
        variables
      );

      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

export const isSuperUser = (user: UserSchema | undefined) => {
  if (!user) return false;

  return (
    user?.permissionGroupId?.toString() === "0" &&
    user?.permissionLevelId?.toString() === "0"
  );
};

type useUserGetManyProps = {
  enabled?: boolean;
};
export const useUserGetMany = ({
  enabled = true,
}: useUserGetManyProps = {}) => {
  const [data, setData] = useState<UserSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch users");
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/users-get");

    const users = data?.d ?? [];

    setData(users);

    return users;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useUserGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};
export const useUserGetOneById = ({
  enabled = true,
  id,
}: useUserGetOneByIdProps) => {
  const [data, setData] = useState<UserSchema | undefined>(undefined);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch user");
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", `/api/user-get?id=${id}`);

    const user = data?.d;

    setData(user);

    return user;
  }, [execute, id]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data };
};

type useUserGetAssignedFarmsProps = {
  enabled?: boolean;
  id: string;
};
type assignedFarmSchema = {
  farmname: string;
  farmcode: string;
};
export const useUserGetAssignedFarms = ({
  enabled = true,
  id,
}: useUserGetAssignedFarmsProps) => {
  const [data, setData] = useState<assignedFarmSchema[]>([]);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch user assigned farms");
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/userassignedfarms-get?id=${id}`
    );

    const farms = data?.d ?? [];

    setData(farms);

    return farms as assignedFarmSchema[];
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

export const userCreateSchema = z
  .object({
    accountEnabled: z.boolean(),
    displayName: z.string(),
    emailAddress: z.string().email(),
    username: z
      .string()
      .min(3, "Username must be at least 3 characters.")
      .max(20, "Username must be at most 20 characters."),
    forceChangePasswordNextSignIn: z.boolean(),
    roleId: z.string(),
    assignedFarms: z.array(z.string()),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
    permissionLevelId: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.password && data.confirmPassword) {
        return data.password === data.confirmPassword;
      }

      return true;
    },
    {
      message: "Passwords do not match.",
      path: ["confirmPassword"],
    }
  );
export type UserCreateSchema = z.infer<typeof userCreateSchema>;
export const userUpdateSchema = z
  .object({
    id: z.string().min(1, "User ID is required."),
    displayName: z.string(),
    emailAddress: z.string().email(),
    roleId: z.string(),
    permissionLevelId: z.string().optional(),
    assignedFarms: z.array(z.string()),
    password: z.string().optional(),
    confirmPassword: z.string().optional(),
  })
  .refine(
    (data) => {
      // if password provided ensure it meets the min and max length
      if (data.password) {
        return data.password.length >= 8 && data.password.length <= 20;
      }

      return true;
    },
    {
      message: "Password must be between 8 and 20 characters.",
      path: ["password"],
    }
  )
  .refine(
    (data) => {
      if (
        !isNullEmptyOrWhitespace(data.password) &&
        isNullEmptyOrWhitespace(data.confirmPassword)
      ) {
        return false;
      }

      return true;
    },
    {
      message: "Confirm password is required.",
      path: ["confirmPassword"],
    }
  )
  .refine(
    (data) => {
      if (data.password && data.confirmPassword) {
        return data.password === data.confirmPassword;
      }

      return true;
    },
    {
      message: "Passwords do not match.",
      path: ["confirmPassword"],
    }
  );
export type UserUpdateSchema = z.infer<typeof userUpdateSchema>;
type useUserMutateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserMutate = ({
  onSuccess,
  onError,
}: useUserMutateProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (user: UserCreateSchema | UserUpdateSchema) => {
      if ("id" in user) {
        const { data } = await execute("PUT", "/api/user-put", user);
        return data?.d;
      } else {
        const { data } = await execute("POST", "/api/user-post", user);
        return data?.d;
      }
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserDeleteProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserDelete = ({
  onSuccess,
  onError,
}: useUserDeleteProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.write,
    },
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (id: string) => {
      const { data } = await execute("DELETE", "/api/user-delete", {
        id,
      });
      return data?.d;
    },
    [execute]
  );

  return { isLoading, isFetched, error, mutate };
};

type useUserSignInProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignIn = ({
  onSuccess,
  onError,
}: useUserSignInProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: (response, responseBody) => {
      return onSuccess?.(response, responseBody);
    },
    onError,
  });

  const mutate = useCallback(
    async (credentials: { username: string; password: string }) => {
      const { data } = await execute("POST", "/api/signin", {
        ...credentials,
      });

      return data?.d;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useUserSignOutProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useUserSignOut = ({
  onSuccess,
  onError,
}: useUserSignOutProps = {}) => {
  const { instance } = useMsal();
  const { execute } = useFetch({
    onSuccess: (response, responseBody) => {
      if (responseBody?.status === 200) {
        setUser(undefined);
        signOutUser();

        return onSuccess?.(response, responseBody);
      }

      return onError?.(responseBody?.message);
    },
    onError: (errorMesssage) => {
      // We assume that the error is related to the 'token' cookie not
      // being set on the server side, so we clear the browser cache
      setUser(undefined);
      signOutUser();

      return onError?.(errorMesssage);
    },
  });
  const { setUser } = useUser();

  const mutate = useCallback(async () => {
    if (authConfig.mode === "b2c") {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: "/user/login",
      });
    }

    const { data } = await execute("POST", "/api/signout");

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  return { mutate };
};

export const userFormSchema = z.object({
  legacyPermLevelOptions: z.array(
    z.object({ value: z.number(), text: z.string() })
  ),
});
export type UserFormSchema = z.infer<typeof userFormSchema>;
type useUserFormProps = {
  enabled?: boolean;
};
export const useUserForm = ({ enabled = true }: useUserFormProps = {}) => {
  const [data, setData] = useState<UserFormSchema | undefined>(undefined);

  const { isLoading, isFetched, error, execute } = useFetch({
    msalRequest: {
      scopes: protectedResources.api.scopes.read,
    },
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch user form");
    },
  });

  const fetchData = useCallback(async () => {
    const { data } = await execute("GET", "/api/user-form-get");

    const userForm = data?.d;

    setData(userForm);

    return userForm;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

function signOutUser() {
  // delete cookie
  deleteCookie("signedIn");

  // clear all session storage
  sessionStorage.clear();

  // clear browser cache
  clearBrowserCache();
}
