import Card from "components/Card";
import Logo from "assets/Logo";
import { authConfig } from "authConfig";
import SignupForm from "./_signup-form";
import { Link } from "react-router-dom";
import Error404 from "pages/Error404";
import { Alert } from "components/core";

export default function Signup() {
  if (authConfig.mode !== "b2c") {
    // show 404 page
    return (
      <>
        <div className="flex min-h-screen flex-1 items-center bg-gray-100 text-center">
          <div className="flex-1 flex-row justify-center space-y-2">
            <div className="mx-auto w-full max-w-xl p-4 tablet:p-0">
              <Card className="p-4 tablet:p-8" bodyClassName="space-y-6">
                <Link to="/" className="block">
                  <Logo className="mx-auto h-24 w-auto text-gray-900" />
                </Link>
                <Error404 />
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex min-h-screen flex-1 items-center bg-gray-100 text-center">
        <div className="flex-1 flex-row justify-center space-y-2">
          <div className="mx-auto w-full max-w-xl p-4 tablet:p-0 text-left">
            <Card className="p-4 tablet:p-8" bodyClassName="space-y-6">
              <Logo className="mx-auto h-24 w-auto text-gray-900" />
              {!navigator.onLine ? (
                <Alert theme="warning">
                  You are offline. Please check your internet connection.
                </Alert>
              ) : (
                <SignupForm />
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
