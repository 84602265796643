import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { zodResolver } from "@hookform/resolvers/zod";
import Card, { CardSkeleton, CardTitle } from "components/Card";
import Button from "components/placement/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { Input } from "components/placement/Input";
import {
  userSignupSchema,
  UserSignupSchema,
  useUserSignup,
} from "hooks/useUser";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * TODO: we need to check if the user is already registered before showing this form
 */
const SignupForm = () => {
  const navigate = useNavigate();

  // get user data from msal
  const { accounts, inProgress } = useMsal();
  const account = accounts[0];

  // save the user data
  const { mutate } = useUserSignup({
    onSuccess: () => {
      toast.success("Signup successful.");

      navigate("/user/login");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<UserSignupSchema>({
    defaultValues: {
      email: account?.username ?? "",
      displayName: account?.name,
    },
    resolver: zodResolver(userSignupSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (account) {
      resetForm({
        email: account.username,
        displayName: account.name,
      });
    }
  }, [account, resetForm]);

  if (inProgress !== InteractionStatus.None) {
    return <CardSkeleton />;
  }

  const onSubmit = (data: UserSignupSchema) => {
    mutate(data);
  };

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <Card>
            <CardTitle>Signup</CardTitle>
            <div className="space-y-4">
              <FormField
                control={reactForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Display Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <div className="flex justify-between">
            <Button
              type="button"
              onClick={() => {
                navigate("/user/login");
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Finish Signup
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default SignupForm;
